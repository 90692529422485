/*
NOTE: this file only temporarily resides in scratch-gui.
Nearly identical code appears in scratch-www, and the two should
eventually be consolidated.
*/
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages} from 'react-intl';

import MenuBarMenu from './menu-bar-menu.jsx';

import styles from './login-dropdown.css';
import Spinner from '../spinner/spinner.jsx';
import LmsClient from '../../lib/lms/LmsClient';

// these are here as a hack to get them translated, so that equivalent messages will be translated
// when passed in from www via gui's renderLogin() function
const LoginDropdownMessages = defineMessages({ // eslint-disable-line no-unused-vars
    username: {
        defaultMessage: 'Username',
        description: 'Label for login username input',
        id: 'general.username'
    },
    password: {
        defaultMessage: 'Password',
        description: 'Label for login password input',
        id: 'general.password'
    },
    signin: {
        defaultMessage: 'Sign in',
        description: 'Button text for user to sign in',
        id: 'general.signIn'
    },
    needhelp: {
        defaultMessage: 'Need Help?',
        description: 'Button text for user to indicate that they need help',
        id: 'login.needHelp'
    },
    validationRequired: {
        defaultMessage: 'This field is required',
        description: 'Message to tell user they must enter text in a form field',
        id: 'form.validationRequired'
    }
});

let errorDivRef = React.createRef();
let spinnerDivRef = React.createRef();
let usernameRef = React.createRef();
let passwordRef = React.createRef();
let loginButtonRef = React.createRef();

const LoginDropdown = ({
    className,
    isOpen,
    isRtl,
    dispatchLogin,
    onClose
}) => {

    let formUsername = "";
    let formPassword = "";

    function usernameChanged(event){
        formUsername = event.target.value;
    }

    function passwordChanged(event){
        formPassword = event.target.value;
    }

    function setLoginInProgressUI(){
        //show spinner
        spinnerDivRef.current.style.visibility = "visible";
        //disable form inputs and button
        usernameRef.current.disabled = true;
        passwordRef.current.disabled = true;
        loginButtonRef.current.disabled = true;
    }

    function setLoginFinishedUI(err){
        if(err){
            errorDivRef.current.innerHTML = "<strong>Login failed!</strong>"
        }
        //show spinner
        spinnerDivRef.current.style.visibility = "hidden";
        //disable form inputs and button
        usernameRef.current.disabled = false;
        passwordRef.current.disabled = false;
        loginButtonRef.current.disabled = false;
    }

    
    function doLogin(event) {     
        setLoginInProgressUI(); 
        new LmsClient().login(formUsername, formPassword).then(response => {
            new LmsClient(response.id_token).fetchStudentInfo().then(studentInfoJSON => {
                //update Redux state
                const loginData = {
                    username: formUsername,
                    authtoken: response.id_token,
                    accountData: studentInfoJSON
                }
                //store login data in local storage
                const serialisedState = JSON.stringify(loginData);
                localStorage.setItem("scratchGuiLoginData", serialisedState);

                //dispatch login data to update redux state
                dispatchLogin(loginData); 
                setLoginFinishedUI();
            }).catch(err => {setLoginFinishedUI(err)});
        }).catch(err => {
            setLoginFinishedUI(err);
        });
    }

    return (
        <MenuBarMenu
            className={className}
            open={isOpen}
            // note: the Rtl styles are switched here, because this menu is justified
            // opposite all the others
            place={isRtl ? 'right' : 'left'}
            onRequestClose={onClose}
        >
            <div
                className={classNames(
                    styles.login
                )}
            >
                <div ref={errorDivRef}></div>
                <input ref={usernameRef} className={styles.input} type="text" name="LoginUsername" placeholder="Username" onChange={usernameChanged}></input>
                <input ref={passwordRef}  className={styles.input} type="password" name="LoginPassword" placeholder="Password" onChange={passwordChanged}></input>
                <button  ref={loginButtonRef} className={styles.button} onClick={doLogin}><span>Sign in</span></button>
                <div ref={spinnerDivRef} className={styles.spinnerWrapper}  style={{visibility: "hidden"}}>
                            <Spinner
                                small
                                level="info"
                            />
                        </div>
            </div>
           
        </MenuBarMenu>
        );
    };

LoginDropdown.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isRtl: PropTypes.bool,
    onClose: PropTypes.func,
    //renderLogin: PropTypes.func
};

const mapStateToProps = state => ({
    loginData: state.scratchGui.login
});

const mapDispatchToProps = dispatch => ({
    dispatchLogin: (data) => dispatch({type : 'login', payload: data})
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDropdown);
