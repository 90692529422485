import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import decksLibraryContent from '../lib/libraries/decks/index.jsx';
import tutorialTags from '../lib/libraries/tutorial-tags';

import analytics from '../lib/analytics';
import {notScratchDesktop} from '../lib/isScratchDesktop';

import LibraryComponent from '../components/library/library.jsx';

import {connect} from 'react-redux';

import {
    closeTipsLibrary
} from '../reducers/modals';

import {
    activateDeck, replaceDecks
} from '../reducers/cards';
import log from "../lib/log";
import LmsClient from "../lib/lms/LmsClient";

const lmsClient = new LmsClient();

const messages = defineMessages({
    tipsLibraryTitle: {
        defaultMessage: 'Choose a Tutorial',
        description: 'Heading for the help/tutorials library',
        id: 'gui.tipsLibrary.tutorials'
    }
});

class TipsLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
        this.state = {
            data: {},
            loading: true,
            error: null
        };
    }
    handleItemSelect (item) {
        analytics.event({
            category: 'library',
            action: 'Select How-to',
            label: item.id
        });

        /*
            Support tutorials that require specific starter projects.
            If a tutorial declares "requiredProjectId", check that the URL contains
            it. If it is not, open a new page with this tutorial and project id.

            TODO remove this at first opportunity. If this is still here after HOC2018,
                 blame Eric R. Andrew is also on record saying "this is temporary".
            UPDATE well now Paul is wrapped into this as well. Sigh...
                eventually we will find a solution that doesn't involve loading a whole project
        */
        if (item.requiredProjectId && (item.requiredProjectId !== this.props.projectId)) {
            this.loadProjectWIthTutorial(item.requiredProjectId, item.id);
        }

        log.info("Activate deck", item.id);
        this.props.onActivateDeck(item.id);
    }

    // TODO extract this into a shared place and call it from templates-library.jsx too
    loadProjectWIthTutorial = function(projectId, tutorialId) {
        log.info("Trying to download project from Scratch store...")
        let storage = this.props.vm.runtime.storage;
        // fetch project asset
        fetch('https://proxy.cors.sh/https://api.scratch.mit.edu/projects/' + projectId, {
            headers: {
                "pragma": "no-cache",
                "cache-control": "no-cache",
                "x-cors-api-key": "temp_7a62740c5040c81bd3e51f204539d047"
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json().then(responseData => {
                    storage.setProjectToken(responseData.project_token);
                });
            } else {
                console.error('Request failed with status:', response.status);
            }
        })
        .then(projectAsset => {
            return this.props.vm.downloadProjectId(projectId);
        })
        .then(prevResult => {
            log.info("Activate deck", tutorialId);
            this.props.onActivateDeck(tutorialId);
        })
        .catch(error => {
            log.error('Error fetching data:', error);
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        try {
            this.setState({ data: {}, loading: true, error: null });

            const projectTutorials = await lmsClient.fetchProjectTutorials();
            if (projectTutorials.error) {
                this.setState({ data: {}, loading: false, error: projectTutorials.error });
            } else {
                this.setState({ data: projectTutorials.data, loading: false, error: null });
                this.props.onReplaceDecks(projectTutorials.data);
            }
        } catch (error) {
            log.error("Error happened", error);
            this.setState({ data: {}, loading: false, error: error.message });
        }
    }

    render () {
        // decksLibraryContent
        const decksLibraryThumbnailData = Object.keys(decksLibraryContent)
            .filter(id => {
                if (notScratchDesktop()) return true; // Do not filter anything in online editor
                const deck = decksLibraryContent[id];
                // Scratch Desktop doesn't want tutorials with `requiredProjectId`
                if (deck.hasOwnProperty('requiredProjectId')) return false;
                // Scratch Desktop should not load tutorials that are _only_ videos
                if (deck.steps.filter(s => s.title).length === 0) return false;
                // Allow any other tutorials
                return true;
            })
            .map(id => ({
                rawURL: decksLibraryContent[id].img,
                id: id,
                name: decksLibraryContent[id].name,
                featured: true,
                tags: decksLibraryContent[id].tags,
                urlId: decksLibraryContent[id].urlId,
                requiredProjectId: decksLibraryContent[id].requiredProjectId,
                hidden: decksLibraryContent[id].hidden || false
            }));

        const tutorialsData = Object.keys(this.state.data)
            .filter(id => {
                if (notScratchDesktop()) return true; // Do not filter anything in online editor
                const tutorial = this.state.data[id];
                // Scratch Desktop doesn't want templates with `requiredProjectId`
                if (tutorial.hasOwnProperty('requiredProjectId')) return false;
                // Scratch Desktop should not load templates that are _only_ videos
                // if (deck.steps.filter(s => s.title).length === 0) return false;
                // Allow any other templates
                return true;
            })
            .map(id => ({
                rawURL: this.state.data[id].img,
                id: id,
                name: this.state.data[id].name,
                featured: true,
                tags: this.state.data[id].tags,
                urlId: this.state.data[id].urlId,
                requiredProjectId: this.state.data[id].requiredProjectId,
                hidden: this.state.data[id].hidden || false
            }));

        if (!this.props.visible) return null;
        return (
                <LibraryComponent
                    filterable
                    data={tutorialsData}
                    // data={decksLibraryThumbnailData}
                    id="tipsLibraryBackend"
                    tags={tutorialTags}
                    title={this.props.intl.formatMessage(messages.tipsLibraryTitle)}
                    visible={this.props.visible}
                    onItemSelected={this.handleItemSelect}
                    onRequestClose={this.props.onRequestClose}
                />

        );
    }
}

TipsLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateDeck: PropTypes.func.isRequired,
    onReplaceDecks: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.tipsLibrary,
    projectId: state.scratchGui.projectState.projectId,
    vm: state.scratchGui.vm,
});

const mapDispatchToProps = dispatch => ({
    onActivateDeck: id => dispatch(activateDeck(id)),
    onRequestClose: () => dispatch(closeTipsLibrary()),
    onReplaceDecks: decks => dispatch(replaceDecks(decks))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(TipsLibrary));
