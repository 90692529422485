exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.login-dropdown_login_2HoP0 {\n    padding: .625rem;\n}\n\n\n.login-dropdown_input_28yEC {\n    -webkit-transition: all .5s ease;\n    transition: all .5s ease;\n    margin-bottom: 0.75rem;\n    border: 1px solid rgba(0,0,0,.1);\n    border-radius: 5px;\n    background-color: #fafafa;\n    padding: 0 1rem;\n    height: 3rem;\n    color: #575e75;\n    font-size: .875rem;\n    display: block;\n}\n\n\n.login-dropdown_button_1rN-f {\n    display: inline-block;\n    margin-top: 5px;\n    border: 0;\n    border-radius: 0.5rem;\n    cursor: pointer;\n    padding: 0.75em;\n    color: #fff;\n    font-size: .8rem;\n    font-weight: bold;\n    background-color: #fff;\n    color: hsla(215, 100%, 65%, 1);\n}\n\n\n.login-dropdown_spinner-wrapper_2ZpMf {\n    width: 100%;\n    height: 100%;\n    display: inline;\n    padding: 10px;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n\n}\n", ""]);

// exports
exports.locals = {
	"login": "login-dropdown_login_2HoP0",
	"input": "login-dropdown_input_28yEC",
	"button": "login-dropdown_button_1rN-f",
	"spinner-wrapper": "login-dropdown_spinner-wrapper_2ZpMf",
	"spinnerWrapper": "login-dropdown_spinner-wrapper_2ZpMf"
};