import LmsClient from "../lib/lms/LmsClient";

const SAVE_USER_PROJECT = 'scratch-gui/project-save/SAVE_USER_PROJECT';

const initialState = "";

const lmsClient = new LmsClient();
// TODO not needed and can be deleted?
const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SAVE_USER_PROJECT:
        console.log("Received SAVE_USER_PROJECT event: " + action.payload);
        return action.payload;
    default:
        return state;
    }
};

const saveUserProject = (projectName) => ({
    type: SAVE_USER_PROJECT,
    payload: projectName
});

export {
    reducer as default,
    initialState as projectSaveInitialState,
    saveUserProject
};
