import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants.js';
import {STAGE_SIZE_MODES} from '../lib/layout-constants.js';

const SET_STAGE_SIZE = 'scratch-gui/StageSize/SET_STAGE_SIZE';

const initialState = {
    stageSize: STAGE_DISPLAY_SIZES.large
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_STAGE_SIZE:
        return {
            stageSize: action.stageSize
        };
    default:
        return state;
    }
};

const setStageSize = function (stageSize) {
	if (stageSize === STAGE_SIZE_MODES.videoStream){
		document.getElementById("stageBox").style.display = "none";
		document.getElementById("videoStreamBox").style.display = "block";
	} else {
		document.getElementById("stageBox").style.display = "block";
		document.getElementById("videoStreamBox").style.display = "none";
	}
	
    return {
        type: SET_STAGE_SIZE,
        stageSize: stageSize
    };
};

export {
    reducer as default,
    initialState as stageSizeInitialState,
    setStageSize
};
