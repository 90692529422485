import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import analytics from '../lib/analytics';
import {notScratchDesktop} from '../lib/isScratchDesktop';

import LibraryComponent from '../components/library/library.jsx';

import {connect} from 'react-redux';
import LmsClient from '../lib/lms/LmsClient';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';

import {
    closeLoadingProject,
    closeTemplatesLibrary
} from '../reducers/modals';

import templateTags from "../lib/libraries/template-tags";
import log from "../lib/log";
import {closeFileMenu} from "../reducers/menus";
import styles from "../components/library/library.css";
import Spinner from "../components/spinner/spinner.jsx";
import Modal from "../components/modal/modal.jsx";

const lmsClient = new LmsClient();

const messages = defineMessages({
    templatesLibraryTitle: {
        defaultMessage: 'Choose a Template',
        description: 'Heading for the help/templates library',
        id: 'gui.templatesLibrary.templates'
    }
});

class TemplatesLibrary extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
        this.state = {
            data: {},
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
            log.info("Fetch data - project JSON", this.props.vm.toJSON());

        try {
            this.setState({ data: {}, loading: true, error: null });

            const projectTemplates = lmsClient.fetchProjectTemplates();
            if (projectTemplates.error) {
                this.setState({ data: {}, loading: false, error: projectTemplates.error });
            } else {
                this.setState({ data: (await projectTemplates).data, loading: false, error: null });
            }
        } catch (error) {
            log.error("Error happened", error);
            this.setState({ data: {}, loading: false, error: error.message });
        }
    }

    handleItemSelect (item) {
        const {
            loadingState
        } = this.props;

        analytics.event({
            category: 'library',
            action: 'Select Template',
            label: item.id
        });

        // WORKS!!!!
        // this.props.vm.loadProject(item.templateFile);
        // this.props.requestProjectUpload(loadingState);
        log.info("Trying to download project from Scratch store...")
        let storage = this.props.vm.runtime.storage;
        // fetch project asset
        fetch('https://proxy.cors.sh/https://api.scratch.mit.edu/projects/' + item.templateProjectId, {
            headers: {
                "pragma": "no-cache",
                "cache-control": "no-cache",
                "x-cors-api-key": "temp_7a62740c5040c81bd3e51f204539d047"
            }
        })
        // fetch('https://api.scratch.mit.edu/projects/' + item.templateProjectId, {
        //         headers: {
        //             "Sec-Fetch-Mode": "cors",
        //             "Sec-Fetch-Site": "cross-site"
        //         }
        //     })
            .then(response => {
                if (response.ok) {
                    return response.json().then(responseData => {
                        storage.setProjectToken(responseData.project_token);
                    });
                } else {
                    console.error('Request failed with status:', response.status);
                }
            })
            .then(projectAsset => {
                return this.props.vm.downloadProjectId(item.templateProjectId);
            })
            .catch(error => {
                log.error('Error fetching data:', error);
            });
    }
    render () {
        if (this.state.error != null) {
            return (
                <Modal
                    fullScreen
                    contentLabel={this.state.error}
                    id={this.props.id}
                    onRequestClose={this.onRequestClose}>
                    <p>
                        There was an error: {this.state.error}
                    </p>
                </Modal>
            )
        }
        if (this.state.loading) {
            return (
                <Modal
                    fullScreen
                    contentLabel={this.props.title}
                    id={this.props.id}
                    onRequestClose={this.onRequestClose}>
                    <div className={styles.spinnerWrapper}>
                        <Spinner
                            large
                            level="primary"
                        />
                    </div>
                </Modal>
            )
        }
        const templatesData = Object.keys(this.state.data)
            .filter(id => {
                if (notScratchDesktop()) return true; // Do not filter anything in online editor
                const template = this.state.data[id];
                // Scratch Desktop doesn't want templates with `requiredProjectId`
                if (template.hasOwnProperty('requiredProjectId')) return false;
                // Scratch Desktop should not load templates that are _only_ videos
                // if (deck.steps.filter(s => s.title).length === 0) return false;
                // Allow any other templates
                return true;
            })
            .map(id => ({
                rawURL: this.state.data[id].img,
                id: id,
                name: this.state.data[id].name,
                featured: true,
                tags: this.state.data[id].tags,
                // urlId: this.state.data[id].urlId,
                // requiredProjectId: this.state.data[id].requiredProjectId,
                // hidden: this.state.data[id].hidden || false,
                // templateFile: this.state.data[id].templateFile,
                templateProjectId: this.state.data[id].templateProjectId
            }));

        if (!this.props.visible) return null;
        return (
            <LibraryComponent
                filterable
                data={templatesData}
                id="templatesLibrary"
                tags={templateTags}
                title={this.props.intl.formatMessage(messages.templatesLibraryTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

TemplatesLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    onLoadingFinished: PropTypes.func,
    requestProjectUpload: PropTypes.func
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.templatesLibrary,
    projectId: state.scratchGui.projectState.projectId,
    vm: state.scratchGui.vm,
    gui: state.scratchGui
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRequestClose: () => dispatch(closeTemplatesLibrary()),
    onLoadingFinished: (loadingState, success) => {
        dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
        dispatch(closeLoadingProject());
        dispatch(closeFileMenu());
    },
    requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplatesLibrary));
