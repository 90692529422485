import React from 'react';
import {FormattedMessage} from 'react-intl';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

import tm2scratchIconURL from './tm2scratch/tm2scratch.png';
import tm2scratchInsetIconURL from './tm2scratch/tm2scratch-small.png';

import tmpose2scratchIconURL from './tmpose2scratch/tmpose2scratch.png';
import tmpose2scratchInsetIconURL from './tmpose2scratch/tmpose2scratch-small.png';

import legomarioIconURL from './legomario/legomario.png';
import legomarioInsetIconURL from './legomario/legomario-small.svg';
import legomarioConnectionIconURL from './legomario/legomario-illustration.svg';
import legomarioConnectionSmallIconURL from './legomario/legomario-small.svg';
import legomarioConnectionTipIconURL from './legomario/legomario-button-illustration.svg';

import spikeessentialIconURL from './spikeessential/spikeessential.png';
import spikeessentialInsetIconURL from './spikeessential/spikeessential-small.svg';
import spikeessentialConnectionIconURL from './spikeessential/spikeessential-illustration.svg';
import spikeessentialConnectionSmallIconURL from './spikeessential/spikeessential-small.svg';
import spikeessentialConnectionTipIconURL from './spikeessential/spikeessential-button-illustration.svg';

import spikeprimeIconURL from './spikeprime/spikeprime.png';
import spikeprimeInsetIconURL from './spikeprime/spikeprime-small.svg';
import spikeprimeConnectionIconURL from './spikeprime/spikeprime-illustration.svg';
import spikeprimeConnectionSmallIconURL from './spikeprime/spikeprime-small.svg';

import matatabotIconURL from './matatabot/matatabot.png';
import matatabotInsetIconURL from './matatabot/matatabot-small.svg';
import matatabotConnectionIconURL from './matatabot/matatabot-illustration.svg';
import matatabotConnectionSmallIconURL from './matatabot/matatabot-small.svg';

import matataconIconURL from './matatacon/matatacon.png';
import matataconInsetIconURL from './matatacon/matatacon-small.svg';
import matataconConnectionIconURL from './matatacon/matatacon-illustration.svg';
import matataconConnectionSmallIconURL from './matatacon/matatacon-small.svg';

import domain from '../../../config/matatalab-domain.json';

export default [
    {
        name: (
            <FormattedMessage
                defaultMessage="TM2Scratch"
                description="Teachable Machine 2 Scratch"
                id="gui.extension.tm2scratch.name"
            />
        ),
        extensionId: 'tm2scratch',
        collaborator: 'Tsukurusha, YengawaLab and Google',
        iconURL: tm2scratchIconURL,
        insetIconURL: tm2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Teachable Machine with Scratch 3.0"
                description="Description for the 'TM2Scratch' extension"
                id="gui.extension.tm2scratch.description"
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false,
        translationMap: {
            'ja': {
                'gui.extension.tm2scratchblocks.description': '画像や音声を学習させよう。'
            },
            'ja-Hira': {
                'gui.extension.tm2scratchblocks.description': 'がぞうやおんせいをがくしゅうさせよう。'
            },
            'en': {
                'gui.extension.tm2scratchblocks.description': 'Recognize your own images and sounds.'
            },
            'zh-cn': {
                'gui.extension.tm2scratchblocks.description': 'Recognize your own images and sounds.'
            },
            'ko': {
                'gui.extension.tm2scratchblocks.description': '나의 이미지와 소리를 인식해볼까요'
            },
            'zh-tw': {
                'gui.extension.tm2scratchblocks.description': 'Recognize your own images and sounds.'
            }
        }
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="TMPOSE2Scratch"
                description="Recognise your own poses"
                id="gui.extension.tmpose2scratch.name"
            />
        ),
        extensionId: 'tmpose2scratch',
        collaborator: 'Tsukurusha, YengawaLab and Google',
        iconURL: tmpose2scratchIconURL,
        insetIconURL: tmpose2scratchInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Recognize your own poses"
                description="Recognize your own poses"
                id="gui.extension.tmpose2scratch.description"
            />
        ),
        featured: true,
        disabled: false,
        internetConnectionRequired: true,
        bluetoothRequired: false,
        translationMap: {
            'ja': {
                'gui.extension.tmpose2scratchblocks.description': 'ポーズを学習させよう。'
            },
            'ja-Hira': {
                'gui.extension.tmpose2scratchblocks.description': 'ポーズをがくしゅうさせよう。'
            },
            'en': {
                'gui.extension.tmpose2scratchblocks.description': 'Recognize your own poses.'
            },
            'ko': {
                'gui.extension.tmpose2scratchblocks.description': '나의 몸동작 포즈를 인식해볼까요'
            }
        }
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        collaborator: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the 'Makey Makey' extension"
                id="gui.extension.makeymakey.description"
            />
        ),
        featured: true
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier'
    },
    {
        name: 'LEGO Mario',
        extensionId: 'legomario',
        collaborator: 'bricklife',
        iconURL: legomarioIconURL,
        insetIconURL: legomarioInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Know what he is doing."
                id="gui.extension.legomario.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: legomarioConnectionIconURL,
        connectionSmallIconURL: legomarioConnectionSmallIconURL,
        connectionTipIconURL: legomarioConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                id="gui.extension.legomario.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education SPIKE Essential',
        extensionId: 'spikeessential',
        collaborator: 'bricklife',
        iconURL: spikeessentialIconURL,
        insetIconURL: spikeessentialInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                id="gui.extension.spikeessential.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: spikeessentialConnectionIconURL,
        connectionSmallIconURL: spikeessentialConnectionSmallIconURL,
        connectionTipIconURL: spikeessentialConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                id="gui.extension.spikeessential.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education SPIKE Prime (Legacy)',
        extensionId: 'spikeprime',
        collaborator: 'bricklife',
        iconURL: spikeprimeIconURL,
        insetIconURL: spikeprimeInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more. (doesn't work on Windows)"
                id="gui.extension.spikeprime.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: spikeprimeConnectionIconURL,
        connectionSmallIconURL: spikeprimeConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                id="gui.extension.spikeprime.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'MatataBot',
        extensionId: 'matatabot',
        collaborator: 'matatalab',
        iconURL: matatabotIconURL,
        insetIconURL: matatabotInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the MatataBot."
                description="Description for the 'MatataBot' extension"
                id="gui.extension.matatabot.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: matatabotConnectionIconURL,
        connectionSmallIconURL: matatabotConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their MatataBot."
                id="gui.extension.matatabot.connectingMessage"
            />
        ),
        helpLink: domain.matatbot_help_link
    },
    {
        name: 'MatataCon',
        extensionId: 'matatacon',
        collaborator: 'matatalab',
        iconURL: matataconIconURL,
        insetIconURL: matataconInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the MatataCon."
                description="Description for the 'MatataCon' extension"
                id="gui.extension.matatacon.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: matataconConnectionIconURL,
        connectionSmallIconURL: matataconConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their MatataCon."
                id="gui.extension.matatacon.connectingMessage"
            />
        ),
        helpLink: domain.matatcon_help_link
    }
];
