import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, intlShape, defineMessages} from 'react-intl';

import analytics from '../lib/analytics';
import {notScratchDesktop} from '../lib/isScratchDesktop';

import LibraryComponent from '../components/library/library.jsx';

import {connect} from 'react-redux';
import LmsClient from '../lib/lms/LmsClient';
import scratchIcon from './scratch-icon.png';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';

import {
    closeLoadingProject,
    closeUserProjects
} from '../reducers/modals';

import log from "../lib/log";
import {closeFileMenu} from "../reducers/menus";
import styles from "../components/library/library.css";
import Spinner from "../components/spinner/spinner.jsx";
import Modal from "../components/modal/modal.jsx";

const lmsClient = new LmsClient();

const messages = defineMessages({
    userProjectsTitle: {
        defaultMessage: 'Choose a Project',
        description: 'Your saved projects',
        id: 'gui.userProjects.userProjects'
    }
});

class UserProjectPicker extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
        this.state = {
            data: {},
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        log.info("FETCH DATA");

        try {
            this.setState({ data: {}, loading: true, error: null });

            const userProjects = lmsClient.fetchUserProjects();
            if (userProjects.error) {
                this.setState({ data: {}, loading: false, error: userProjects.error });
            } else {
                this.setState({ data: (await userProjects).data, loading: false, error: null });
            }
        } catch (error) {
            log.error("Exception while fetching user projects", error);
            this.setState({ data: {}, loading: false, error: error.message });
        }
    }

    handleItemSelect (item) {

        const serialisedState = localStorage.getItem("scratchGuiLoginData");
        if (serialisedState === null) {
            throw new Error("Only logged in users can fetch user projects");
        }
            const jsonData = JSON.parse(serialisedState);
            const loginState = {
                username: jsonData.username,
                authtoken: jsonData.authtoken,
                accountData: jsonData.accountData
            }
        this.props.vm.downloadProjectFromURLDirect(lmsClient.getUserProjectUrl(loginState.username, item.name), loginState.authtoken);
    }
    render () {
        if (this.state.error != null) {
            return (
                <Modal
                    fullScreen
                    contentLabel="Some error..."
                    id={this.props.id}
                    onRequestClose={this.onRequestClose}>
                    <p>
                        There was an error: {this.state.error}
                    </p>
                </Modal>
            )
        }
        if (this.state.loading) {
            return (
                <Modal
                    fullScreen
                    contentLabel="Loading...Đ"
                    id={this.props.id}
                    onRequestClose={this.onRequestClose}>
                    <div className={styles.spinnerWrapper}>
                        <Spinner
                            large
                            level="primary"
                        />
                    </div>
                </Modal>
            )
        }
        const userProjectsData = this.state.data
            .map(item => ({
                rawURL: scratchIcon,
                id: item,
                name: item,
                featured: true
            }));

        if (!this.props.visible) return null;
        return (
            <LibraryComponent
                filterable={false}
                data={userProjectsData}
                id="userProjects"
                title={this.props.intl.formatMessage(messages.userProjectsTitle)}
                visible={this.props.visible}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

UserProjectPicker.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    onLoadingFinished: PropTypes.func,
    requestProjectUpload: PropTypes.func,
    onClose: PropTypes.func
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.userProjects,
    projectId: state.scratchGui.projectState.projectId,
    vm: state.scratchGui.vm,
    gui: state.scratchGui
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onRequestClose: () => dispatch(closeUserProjects()),
    onLoadingFinished: (loadingState, success) => {
        dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
        dispatch(closeLoadingProject());
        dispatch(closeFileMenu());
    },
    requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProjectPicker));
