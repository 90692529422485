let initialState = {
    username: "",
    authtoken: "",
    accountData: null
};

const serialisedState = localStorage.getItem("scratchGuiLoginData");
if (serialisedState !== null) {
    const jsonData = JSON.parse(serialisedState);
    initialState = {
        username: jsonData.username,
        authtoken: jsonData.authtoken,
        accountData: jsonData.accountData
    }
}

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    if (action.type === 'login'){
        return {
            username: action.payload.username,
            authtoken: action.payload.authtoken,
            accountData: action.payload.accountData
        }
    } else if (action.type === 'logout'){
        return {
            username: "",
            authtoken:"",
            accountData: null

        }
    }
    return state;
};


export {
    reducer as default,
    initialState as loginInitialState
};
