exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-info-dropdown_accountInfo_2Sgin {\n    padding: .625rem;\n}\n\n.account-info-dropdown_info-menu-item-icon_2PHCG {\n    height: 1.25rem;\n    vertical-align: middle;\n    padding-right: 3px;\n}\n\n.account-info-dropdown_account-name_ZXg2P{\n    line-height: 34px;\n    padding-left: 10px;\n}\n\n\n", ""]);

// exports
exports.locals = {
	"accountInfo": "account-info-dropdown_accountInfo_2Sgin",
	"info-menu-item-icon": "account-info-dropdown_info-menu-item-icon_2PHCG",
	"infoMenuItemIcon": "account-info-dropdown_info-menu-item-icon_2PHCG",
	"account-name": "account-info-dropdown_account-name_ZXg2P",
	"accountName": "account-info-dropdown_account-name_ZXg2P"
};