
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages} from 'react-intl';
import {openAccountInfoModal, closeAccountInfoModal, openTemplates} from '../../reducers/modals';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import logoutIcon from './logout.svg';
import accountHeadIcon from './account-info-head.svg';

import MenuBarMenu from './menu-bar-menu.jsx';

import styles from './account-info-dropdown.css';

// these are here as a hack to get them translated, so that equivalent messages will be translated
// when passed in from www via gui's renderLogin() function
const AccountInfoDropdownMessages = defineMessages({ // eslint-disable-line no-unused-vars
    username: {
        defaultMessage: 'Username',
        description: 'Label for login username input',
        id: 'general.username'
    },
    logout: {
        defaultMessage: 'Log out',
        description: 'Label for log out',
        id: 'general.logout'
    }
});

const AccountInfoDropdown = ({
    className,
    isOpen,
    isRtl,
    loginData,
    onOpenAccountInfo,
    onClose,
    dispatchLogout
}) => {

    function doLogout(event) {
        console.log('doLogout!!!')
        //TODO call auth service here
    
        //remove from local storage
        localStorage.removeItem('scratchGuiLoginData');
    
        //dispatch login data to update redux stete
        dispatchLogout({});
    }

    return (
        <MenuBarMenu
            className={className}
            open={isOpen}
            // note: the Rtl styles are switched here, because this menu is justified
            // opposite all the others
            place={isRtl ? 'right' : 'left'}
            onRequestClose={onClose}
        >
                                
                                    <div className={styles.accountName}>{loginData.accountData.firstName} {loginData.accountData.lastName}</div>
                                    <MenuSection>
                                        <MenuItem
                                            isRtl={isRtl}
                                            onClick={onOpenAccountInfo}
                                        >
                                        <div>
                                            <img
                                                className={styles.infoMenuItemIcon}
                                                src={accountHeadIcon}
                                            /><span>Account info</span>
                                        </div>
                                        </MenuItem>
                                        <MenuItem
                                            isRtl={isRtl}
                                            onClick={doLogout}
                                        >
                                        <div>
                                            <img
                                                className={styles.infoMenuItemIcon}
                                                src={logoutIcon}
                                            /><span>Logout</span>
                                        </div>
                                        </MenuItem>
                                    </MenuSection>
                                    



        </MenuBarMenu>
        );
    };

AccountInfoDropdown.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    isRtl: PropTypes.bool,
    onOpenAccountInfo: PropTypes.func,
    onClose: PropTypes.func,
    accoutnInfoVisible: PropTypes.bool,
    dispatchLogout: PropTypes.func
};

const mapStateToProps = state => ({
    loginData: state.scratchGui.login
});

const mapDispatchToProps = dispatch => ({
    dispatchLogout: (data) => dispatch({type : 'logout', payload: data}),
    onOpenAccountInfo: () => dispatch(openAccountInfoModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoDropdown);
