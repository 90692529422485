
import PropTypes from 'prop-types';
import React from 'react';
import {injectIntl, defineMessages} from 'react-intl';

import {connect} from 'react-redux';
import { closeAccountInfoModal } from '../reducers/modals';
import Modal from './modal.jsx';
import styles from './account-info-page.css';
import profileImage from './account-profile-image.png';

const messages = defineMessages({
    accountInfoTitle: {
        defaultMessage: 'Account details',
        description: 'Account information page',
        id: 'gui.accountInfoPage'
    }
});

class AccountInfoPage extends React.PureComponent {
    constructor (props) {
        super(props);
    }

    componentDidMount() { 
    }

    render () {
        if (!this.props.visible) return null;
        return (
            <Modal
                fullScreen
                contentLabel={messages.accountInfoTitle.defaultMessage}
                id={messages.id}
                onRequestClose={this.props.handleModalClose}
            >

                <div className={styles.profile}>
                    <div className={styles.profileImage}>
                        <img src={profileImage} alt="User Image" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>   
                    </div>
                    <div className={styles.profileDetails}>
                        <div className={styles.profileDetailsSection}>
                            <h3>Personal Information</h3>
                            <ul>
                                <li>Name: {this.props.accountData.firstName}</li>
                                <li>Last Name: {this.props.accountData.lastName}</li>
                                <li>Birthdate: {this.props.accountData.birthDate}</li>
                            </ul>
                        </div>
                        <div className={styles.profileDetailsSection}>
                            <h3>Course information</h3>
                            <ul>
                                <li>Course type: {this.props.accountData.courseDTO.courseType}</li>
                                <li>Name: {this.props.accountData.courseDTO.name}</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </Modal>  
        );
    }
}

AccountInfoPage.propTypes = {
    visible: PropTypes.bool,
    handleModalClose: PropTypes.func,
    accountData: PropTypes.any
};

const mapStateToProps = state => ({
    visible: state.scratchGui.modals.accountInfoPage,
    accountData: state.scratchGui.login.accountData
});

const mapDispatchToProps = dispatch => ({
    handleModalClose: () => dispatch(closeAccountInfoModal())
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountInfoPage));
