exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".account-info-page_profile_3kArX {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    border-radius: 5px;\n    overflow: hidden;\n    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    background-color: transparent;\n}\n\n.account-info-page_profile-image_DjACP {\n    -webkit-box-flex: 0;\n    -webkit-flex: 0 0 200px;\n        -ms-flex: 0 0 200px;\n            flex: 0 0 200px;\n    height: 300px;\n}\n\n.account-info-page_profile-details_1lgVg {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    padding: 20px;\n}\n\n.account-info-page_profile-details_1lgVg h2 {\n    margin-top: 0;\n}\n\n.account-info-page_profile-details-section_3ssFL {\n    margin-bottom: 20px;\n}\n\n.account-info-page_profile-details-section_3ssFL h3 {\n    margin-top: 0;\n}\n\n.account-info-page_profile-details-section_3ssFL ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\n.account-info-page_profile-details-section_3ssFL li {\n    margin-bottom: 5px;\n}\n\n/* Responsive */\n\n@media (max-width: 768px) {\n    .account-info-page_profile_3kArX {\n        -webkit-box-orient: vertical;\n        -webkit-box-direction: normal;\n        -webkit-flex-direction: column;\n            -ms-flex-direction: column;\n                flex-direction: column;\n    }\n\n    .account-info-page_profile-image_DjACP {\n        width: 100%;\n        height: auto;\n    }\n\n    .account-info-page_profile-details_1lgVg {\n        padding: 20px;\n    }\n}", ""]);

// exports
exports.locals = {
	"profile": "account-info-page_profile_3kArX",
	"profile-image": "account-info-page_profile-image_DjACP",
	"profileImage": "account-info-page_profile-image_DjACP",
	"profile-details": "account-info-page_profile-details_1lgVg",
	"profileDetails": "account-info-page_profile-details_1lgVg",
	"profile-details-section": "account-info-page_profile-details-section_3ssFL",
	"profileDetailsSection": "account-info-page_profile-details-section_3ssFL"
};