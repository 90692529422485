import log from "../log";

class LmsClient {
    constructor(token) {
        this.authenticationToken = token;
        this.baseUrl = process.env.LMS_API_URL;
    }

    async login(username, password) {
        //prepare loign request
        const loginRequest = {
            username: username,
            password: password,
            rememberMe: false
        }
        const loginOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                "pragma": "no-cache",
                "cache-control": "no-cache",
                "x-cors-api-key": "temp_7a62740c5040c81bd3e51f204539d047"
            },
            body: JSON.stringify(loginRequest)
        };

        //call auth service
        const loginResponse = await fetch(this.baseUrl + '/api/authenticate', loginOptions);
        log.info("Login response", loginResponse);
        if (!loginResponse.ok) {
            throw new Error('Failed to login!');
        }
        const loginJsonData = await loginResponse.json();
        log.info("Here is login json:", loginJsonData);
        return loginJsonData;
    }

    async fetchStudentInfo(){
        if (!this.authenticationToken){
            throw new Error('Unable to fetch student info without authentication token');
        }
        //prepare student info request
        const studentInfoOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json',
                "pragma": "no-cache",
                "cache-control": "no-cache",
                "x-cors-api-key": "temp_7a62740c5040c81bd3e51f204539d047",
                "Authorization": "Bearer " + this.authenticationToken
            }
        };
        //call student info service
        const studentInfoResponse = await fetch(this.baseUrl + '/api/students/info', studentInfoOptions);
        log.info("Student info response", studentInfoResponse);
        if (!studentInfoResponse.ok) {
            throw new Error('Failed to fetch student info!');
        }
        const studentInfoJsonData = await studentInfoResponse.json();
        log.info("Here is student info json:", studentInfoJsonData);
        return studentInfoJsonData;
    }

    async fetchProjectTemplates() {
        let result = { data: {}, error: null}

        let loginData = localStorage.getItem("scratchGuiLoginData");
        log.info(loginData);
        if (!loginData) {
            log.warn("There is no login data");
            result.error = 'You must log in first';
            return result;
        }
        loginData = JSON.parse(loginData);
        const authtoken = loginData.authtoken;
        const tokenExpEncoded = authtoken.toString().split(".")[1]
        const tokenExpDecoded = JSON.parse(atob(tokenExpEncoded)).exp;
        log.info("Token exp decoded", tokenExpDecoded);
        const tokenExp = new Date(tokenExpDecoded * 1000);
        if (tokenExp <= new Date()) {
            // TODO refresh token
            log.error("Token has expired on " + tokenExp);
            // localStorage.removeItem("scratchGuiLoginData");
            result.error = 'The token has expired, you must log in again.';
            // dispatchLogout({});
            return result;
        }
        log.info("Token exp", tokenExp);
        // const response = await fetch('https://run.mocky.io/v3/87fdb993-a51d-4dee-bc74-5da4e9867587');
        const response = await fetch(this.baseUrl + '/api/users/' + loginData.username + '/scratch-template-projects', {
            headers: {
                "Authorization": "Bearer " + loginData.authtoken
            }
        });
        log.info("Response", response);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        log.info("Here is the jsonData", jsonData);
        result.data = jsonData;
        return result;
    }

    // TODO
    async fetchProjectTutorials() {
        let result = { data: {}, error: null}

        let loginData = localStorage.getItem("scratchGuiLoginData");
        log.info(loginData);
        if (!loginData) {
            log.warn("There is no login data");
            result.error = 'You must log in first';
            return result;
        }
        loginData = JSON.parse(loginData);
        const authToken = loginData.authtoken;
        const tokenExpEncoded = authToken.toString().split(".")[1]
        const tokenExpDecoded = JSON.parse(atob(tokenExpEncoded)).exp;
        log.info("Token exp decoded", tokenExpDecoded);
        const tokenExp = new Date(tokenExpDecoded * 1000);
        if (tokenExp <= new Date()) {
            // TODO refresh token
            log.error("Token has expired on " + tokenExp);
            // localStorage.removeItem("scratchGuiLoginData");
            result.error = 'The token has expired, you must log in again.';
            // dispatchLogout({});
            return result;
        }
        log.info("Token exp", tokenExp);
        // const response = await fetch('https://run.mocky.io/v3/87fdb993-a51d-4dee-bc74-5da4e9867587');
        const response = await fetch(this.baseUrl + '/api/users/' + loginData.username + '/scratch-tutorials', {
            headers: {
                "Authorization": "Bearer " + loginData.authtoken
            }
        });
        log.info("Response", response);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        log.info("Here is the jsonData", jsonData);
        result.data = jsonData;
        return result;
    }

    async fetchUserProjects() {
        let result = { data: {}, error: null}

        let loginData = localStorage.getItem("scratchGuiLoginData");
        log.info(loginData);
        if (!loginData) {
            log.warn("There is no login data");
            result.error = 'You must log in first';
            return result;
        }
        loginData = JSON.parse(loginData);
        const authToken = loginData.authtoken;
        const tokenExpEncoded = authToken.toString().split(".")[1]
        const tokenExpDecoded = JSON.parse(atob(tokenExpEncoded)).exp;
        log.info("Token exp decoded", tokenExpDecoded);
        const tokenExp = new Date(tokenExpDecoded * 1000);
        if (tokenExp <= new Date()) {
            // TODO refresh token
            log.error("Token has expired on " + tokenExp);
            // localStorage.removeItem("scratchGuiLoginData");
            result.error = 'The token has expired, you must log in again.';
            // dispatchLogout({});
            return result;
        }
        log.info("Token exp", tokenExp);
        const response = await fetch(this.baseUrl + '/api/scratch-project/' + loginData.username, {
            headers: {
                "Authorization": "Bearer " + loginData.authtoken
            }
        });
        log.info("Response", response);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        log.info("Here is the jsonData", jsonData);
        result.data = jsonData;
        return result;
    }

    getUserProjectUrl(username, projectFile) {
        return this.baseUrl + "/api/scratch-project/" + username + "/" + projectFile;
    }

    getLoginState() {
        const jsonData = JSON.parse(localStorage.getItem("scratchGuiLoginData"));
        const loginState = {
            username: jsonData.username,
            authtoken: jsonData.authtoken,
            accountData: jsonData.accountData
        }
        return loginState;
    }

    getAuthToken() {
        return this.getLoginState().authtoken;
    }

    getUsername() {
        return this.getLoginState().username;
    }
}
export default LmsClient;
